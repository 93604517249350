import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";

import PublicationOrDocumentLinkTemplate from "../../templates/common/PublicationOrDocumentLinkTemplate";

export default function DocumentLink({className, title, document}) {
    return (
        <PublicationOrDocumentLinkTemplate
            className={className}
            title={title}
            document={document} />
    );
}

DocumentLink.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    document: PropTypes.string
};

export const query = graphql`
    fragment DocumentLink on Document {
        id
        title
        document {
            publicURL
        }
    }
`;
