import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Masonry from "react-masonry-css";

import PaginationLinks from "../common/PaginationLinks";
import DocumentLink from "./DocumentLink";

import * as classes from "./Documents.module.scss";

export default function Documents({data, pageContext: {humanPageNumber, previousPagePath, nextPagePath}}) {

    const edges = data["allDocument"]["edges"];

    return (
        <section>
            <Helmet>
                <title>Документы компании{humanPageNumber > 1 ? `, страница ${humanPageNumber}` : ""}</title>
                <meta name="description" content="Открытые корпоративные документы компании: сертификаты, разрешения, лицензии, политики и другое." />
            </Helmet>
            <h1>Документы компании</h1>
            <Masonry
                className={classes.masonryGrid}
                columnClassName={classes.masonryGridColumn}
                breakpointCols={2}>
                {edges?.map(({node}) =>
                    <DocumentLink
                        key={node.id}
                        className={classes.link}
                        title={node["title"]}
                        document={node["document"]["publicURL"]} />)}
            </Masonry>
            <PaginationLinks
                previousLinkText="Предыдущая страница"
                nextLinkText="Следующая страница"
                previousLinkTo={previousPagePath}
                nextLinkTo={nextPagePath} />
        </section>
    );

}

export const query = graphql`
    query($skip: Int, $limit: Int) {
        allDocument(
            sort: {fields: [timestamp], order: DESC}
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    ...DocumentLink
                }
            }
        }
    }
`;
