import React from "react";
import PropTypes from "prop-types";
import {withPrefix} from "gatsby";
import classNames from "classnames";

import SmallCard from "../../components/common/SmallCard";

import * as classes from "./PublicationOrDocumentLinkTemplate.module.scss";

export default function PublicationOrDocumentLinkTemplate({className, title, link, document}) {

    const renderLink = link && !document;

    let linkHostname, fileExtension;
    if (renderLink) {
        try {
            linkHostname = new URL(link).hostname;
        } catch (error) {
            linkHostname = link;
        }
    } else if (document) {
        const parts = document.split(".");
        fileExtension = parts.length > 1 ? `.${parts[parts.length - 1]}` : "Файл";
    }

    return (
        <SmallCard
            className={classNames(classes.container, renderLink && classes.containerLink, className)}
            {...(renderLink ? {
                use: "a",
                href: link,
                target: "_blank",
                rel: "noreferrer"
            } : {})}>
            <h2>{title}</h2>
            {renderLink &&
                <p>
                    Материал опубликован на портале:<br />
                    <span className={classes.linkHostname}>{linkHostname}</span>
                </p>}
            {document &&
                <a
                    className={classes.documentLink}
                    href={withPrefix(document)}
                    target="_blank">
                    Смотреть документ{" "}
                    <em>{fileExtension}</em>
                </a>}
        </SmallCard>
    );

}

PublicationOrDocumentLinkTemplate.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    document: PropTypes.string
};
